import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// toastr
import toastr from 'toastr';

toastr.options = {
  "closeButton": true,
  "positionClass": "toast-bottom-right",
  "timeOut": "3000"
}

global.toastr = toastr;

// bootstrap
import "bootstrap/dist/css/bootstrap";

// stylehseets
import '../stylesheets/shared/body';
import '../stylesheets/shared/toastr';
import '../stylesheets/shared/ckeditor';
import '../stylesheets/shared/tailwindcss';
import "../stylesheets/application/footer";
import "../stylesheets/application/header";
import "../stylesheets/application/company";
import "../stylesheets/application/top";
import "../stylesheets/application/contact";
import "../stylesheets/application/phone";
import "../stylesheets/application/blogs";
import "../stylesheets/application/led";
import "../stylesheets/application/menu";
import "../stylesheets/application/blogs_header";
import "../stylesheets/application/blogs_footer";
import "../stylesheets/application/blog";

Rails.start()
Turbolinks.start()
ActiveStorage.start()
